import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "identification": "spoorConfig",
  "sectionHeader": "Configurations",
  "sectionSubheading": "Choose from one of two different products based on whether you want to track cargo (Spoor) or special cargo (Spoor<sup>SC</sup>).",
  "productTitle1st": "Spoor",
  "productSubtitle1st": "Features of Spoor",
  "spoor": [{
    "feature": "API Access",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoor"
  }, {
    "feature": "Buy or lease our tracker",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoor"
  }, {
    "feature": "Manual consignment details entry",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoor"
  }, {
    "feature": "Periodic updates",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoor"
  }, {
    "feature": "Real-time tracking through ID",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoor"
  }, {
    "feature": "Advanced pairing (SMS, WhatsApp, Android/iOs App, Web)",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoor"
  }, {
    "feature": "Standalone Web/iOS/Android app",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoor"
  }, {
    "feature": "White labeling",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "Excel packing list upload",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "Geofencing",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "Regular updates",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "Route management",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "Use your own tracker",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "User management",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "Customized alerts",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "Customized features",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "Document upload",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "ETA predictions",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "Integration with your IT systems",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "Store status on Blockchain",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoor"
  }, {
    "feature": "Sea freight tracking",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoor"
  }],
  "productTitle2nd": "Spoor SC",
  "productSubtitle2nd": "Features of Spoor Special Cargo",
  "specialCargo": [{
    "feature": "User management",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Buy or lease our tracker",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Excel packing list upload",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Trip History",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Basic pairing (SMS, WhatsApp, Web)",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Periodic Updates",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": true,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "White labeling",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "API access",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Use your own tracker",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Geofencing",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Sea freight tracking",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Regular updates",
    "feature-business": true,
    "feature-professional": true,
    "feature-starter": false,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Condition monitoring",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Advanced Customizations",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Document upload",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Store status on Blockchain",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoorSpecialCargo"
  }, {
    "feature": "Advanced Pairing (SMS, WhatsApp, Android/iOs App, Web)",
    "feature-business": true,
    "feature-professional": false,
    "feature-starter": false,
    "identification": "spoorSpecialCargo"
  }]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      